import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import GapSvg from "../images/Aukkotehtävät_ikoni.svg";
import EssaySvg from "../images/Essee_ikoni.svg";
import CharacterSvg from "../images/Hahmo_koeV2.svg";
import EditorSvg from "../images/Kaava_ikoni.svg";
import CalculatorSvg from "../images/Laskin_ikoni.svg";
import MultipleSvg from "../images/Monivalinta_ikoni.svg";
import TypesSvg from "../images/Tehtävätyypit.svg";
import ValluLogoSvg from "../images/_Vallu_musta.svg";
import ExerciseCard from "./ExerciseCard";
import Wave from "./Wave";

export default function FrontPage() {
  const { t } = useTranslation("common");

  return (
    <>
      <main className="container-fluid">
        <section
          className="row section-light fullHeight align-items-center"
          style={{ paddingBottom: "3rem" }}>
          <Col />
          <Col xs={12} sm={5} xxl={4}>
            <h2 className="fw-bold">{t("title")}</h2>
            <p className="mt-5">{t("infoText")}</p>
          </Col>
          <Col xs={12} sm={5} xxl={4}>
            <img alt="" className="img-fluid" src={CharacterSvg} />
          </Col>
          <Col />
        </section>

        <Wave direction="down" />

        <section
          className="row"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col />
          <Col xs={12} sm={5} xxl={4}>
            <h3 className="fw-bold">{t("getToKnow.title")}</h3>
            <p className="mt-5">{t("getToKnow.body")}</p>
          </Col>
          <Col xs={12} sm={5} xxl={4}>
            <img alt="" className="img-fluid" src={TypesSvg} />
          </Col>
          <Col />
        </section>

        <Wave direction="up" />

        <section
          id="exercise-types"
          className="row section-light"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col />
          <Col xs={10} xxl={8}>
            <h3 className="fw-bold">{t("exampleExercises.title")}</h3>
            <p className="mt-5">{t("exampleExercises.body")}</p>
            <Row className="py-5 gx-4 gx-xl-5">
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <ExerciseCard
                  imgSrc={MultipleSvg}
                  title={t("exampleExercises.multiple.title")}
                  description={t("exampleExercises.multiple.body")}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <ExerciseCard
                  imgSrc={EssaySvg}
                  title={t("exampleExercises.essay.title")}
                  description={t("exampleExercises.essay.body")}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <ExerciseCard
                  imgSrc={GapSvg}
                  title={t("exampleExercises.gap.title")}
                  description={t("exampleExercises.gap.body")}
                />
              </Col>
            </Row>

            <Row
              className="py-5 gx-4 gx-xl-5"
              style={{ borderTop: "1px dashed #ADADAD" }}>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <ExerciseCard
                  imgSrc={CalculatorSvg}
                  title={t("exampleExercises.calculator.title")}
                  description={t("exampleExercises.calculator.body")}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <ExerciseCard
                  imgSrc={EditorSvg}
                  title={t("exampleExercises.editor.title")}
                  description={t("exampleExercises.editor.body")}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                  style={{ height: "12rem" }}
                  className="bg-white rounded-3 px-2 py-1 d-flex flex-column justify-content-center align-items-center">
                  <div className="text-start">
                    <p>
                      <Link to="/guide">
                        {t("exampleExercises.tryEditor.toGuide")}
                      </Link>
                    </p>

                    <p className="m-0">
                      <Link to="/calculator-guide">
                        {t("exampleExercises.tryCalculator.toGuide")}
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              className="py-5 gx-4 gx-xl-5"
              style={{ borderTop: "1px dashed #ADADAD" }}>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                  className="actionButton p-1 py-1 rounded-3 text-white"
                  style={{ height: "12rem" }}>
                  <p
                    style={{ border: "3px dashed #FFF" }}
                    className="h-100 m-0 rounded p-3 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testikoe.korkeakouluun.fi/testikoe/examination.${i18next.language}.html`}
                      className="text-white rounded-3 px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 h-100">
                      {t("exampleExercises.tryExam.toExamples")}
                    </a>
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                  className="actionButton p-1 py-1 rounded-3 text-white"
                  style={{ height: "12rem" }}>
                  <p
                    style={{ border: "3px dashed #FFF" }}
                    className="h-100 m-0 rounded p-3 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testikoe.korkeakouluun.fi/laskin/examination.${i18next.language}.html`}
                      className="text-white rounded-3 px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 h-100">
                      {t("exampleExercises.tryCalculator.toExamples")}
                    </a>
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                  className="actionButton p-1 py-1 rounded-3 text-white"
                  style={{ height: "12rem" }}>
                  <p
                    style={{ border: "3px dashed #FFF" }}
                    className="h-100 m-0 rounded p-3 text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testikoe.korkeakouluun.fi/kaavaeditori/examination.${i18next.language}.html`}
                      className="text-white rounded-3 px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 h-100">
                      {t("exampleExercises.tryEditor.toExamples")}
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                    className="actionButton p-1 py-1 rounded-3 text-white"
                    style={{ height: "12rem" }}>
                  <p
                      style={{ border: "3px dashed #FFF" }}
                      className="h-100 m-0 rounded p-3 text-center">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://testikoe.korkeakouluun.fi/erikoismerkit/examination.${i18next.language}.html`}
                        className="text-white rounded-3 px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 h-100">
                      {t("exampleExercises.trySpecialChars.toExamples")}
                    </a>
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                <div
                    className="actionButton p-1 py-1 rounded-3 text-white"
                    style={{ height: "12rem" }}>
                  <p
                      style={{ border: "3px dashed #FFF" }}
                      className="h-100 m-0 rounded p-3 text-center">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://testikoe.korkeakouluun.fi/vaihtoehtoiset/examination.${i18next.language}.html`}
                        className="text-white rounded-3 px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 h-100">
                      {t("exampleExercises.tryAlternatives.toExamples")}
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col />
        </section>

        <Wave direction="down" />

        <section
          className="row"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col />
          <Col xs={10} xxl={8}>
            <h3 className="fw-bold">{t("accessibility.title")}</h3>
            <p className="mt-5">{t("accessibility.body")}</p>
            <p className="mt-3">
              <Link to="/saavutettavuusseloste">{t("accessibility.link")}</Link>
            </p>
          </Col>
          <Col />
        </section>

        <Wave direction="up" />
      </main>
      <footer className="container-fluid">
        <Row
          className="py-4 section-light"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col
            xs={6}
            sm={3}
            className="order-1 order-sm-0 h-100 align-self-end">
            <img
              alt=""
              className="img-fluid"
              src={ValluLogoSvg}
              style={{ height: "3rem" }}
            />
          </Col>
          <Col
            xs={12}
            sm={6}
            className="order-0 order-sm-1 position-relative text-center">
            <h3 className="w-100 fw-bold text-black py-4">{t("goodluck")}</h3>
          </Col>
          <Col xs={6} sm={3} className="order-2 h-100 align-self-end text-end">
            <p className="mb-0">&copy; {new Date().getFullYear()} Metropolia</p>
          </Col>
        </Row>
      </footer>
    </>
  );
}
